<template>
    <v-card tile class="pa-3 ma-10">
        <v-card-title>
            ユーザープロフィール
        </v-card-title>
        <div v-for="item in profileItems" :key="item.name">
        <v-card-subtitle>{{ item.name }}</v-card-subtitle>
        <v-card-text>{{ item.value }}</v-card-text>
        </div>
    </v-card>
</template>

<script>

export default {
    data: () => ({
        profileItems: [
            { name: "名前", value: "test" },
            { name: "メールアドレス", value: "admin@yht2021.com" }
        ]
    })
}
</script>
